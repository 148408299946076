@tailwind base;
// @layer base {//https://stackoverflow.com/questions/66180213/how-to-prevent-css-libraries-like-tailwindcss-and-bootstrap-to-effect-wysiwyg-ed
// 	// define revert css here
// 	.no-tailwindcss-base {
  
// 	  h1,
// 	  h2,
// 	  h3,
// 	  h4,
// 	  h5,
// 	  h6 {
// 		font-size: revert;
// 		font-weight: revert;
// 	  }
  
// 	  ol,
// 	  ul {
// 		list-style: revert;
// 		margin: revert;
// 		padding: revert;
// 	  }
// 	}
//   }
@tailwind components;
@tailwind utilities;

[x-cloak] {
	display: none !important;
}

body {
	margin: 0 !important;
}

.grecaptcha-badge {
	visibility: hidden;
}
